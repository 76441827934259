import React, { useState } from "react"
import { FaUsers } from "react-icons/fa";
import { FaDesktop } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import "./comparison.scss"



const Comparison = ({ props }) => {

    const [hover, setHover] = useState(false);
    const [hoveredSubtitle, setHoveredSubtitle] = useState(null);

    const onHover = (subtitle) => {
        setHoveredSubtitle(subtitle);
        setHover(true);
    };
    const onLeave = () => {
        setHoveredSubtitle(null);
        setHover(false);
    };

    return (

        // div classname - padding
        <div className="">
            <div className="cards-container">
                {
                    props.title ?
                        <div style={{ margin: "40px 0" }}>
                            <h2 className="card-title">{props.title}</h2>
                            <b />
                        </div>
                        : ""
                }
                {
                    props.twoColumn ?
                        <div className="cards-content uk-child-width-1-2@s uk-grid-small uk-margin-remove uk-text-center" data-uk-grid style={{ display: "flex" }}>
                            {
                                props.comparison.map((item, index) => {
                                    return (

                                        <div onMouseEnter={() => onHover(item.subtitle)} onMouseLeave={onLeave} className={item.background ? "column-background" : ""} style={{ backgroundImage: `url(${item.background})` }} key={index}>
                                            <div className="content-box column-background" key={index} style={hover && hoveredSubtitle === item.subtitle ? { backgroundImage: `url(${item.background})` } : { backgroundImage: `url(${item.overlayImage})` }}>

                                                <p className="item-image">{item.icon == "users" ? <FaUsers fontSize="5rem" color="white" /> : item.icon == "desktop" ? <FaDesktop fontSize="5rem" color="white" /> : item.icon == "star" ? <FaStar fontSize="5rem" color="white" /> : ""}</p>
                                                <h3>{item.subtitle}</h3>
                                                <hr style={{ width: "50px", margin: "10px auto" }} />
                                        
                                                <div className="hover-content">
                                                    {
                                                        item.info ?
                                                            <div className="item-description uk-grid uk-grid-collapse">
                                                                <p className="item-info uk-width-5-6">{item.info}</p>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        item.url ?
                                                            <div style={{ margin: "30px auto" }}>
                                                                <a href={item.url} className="card-btn">{item.label}</a>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                                {/* )} */}

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className="cards-content uk-child-width-1-3@s uk-grid-small uk-margin-remove uk-text-center" data-uk-grid>
                            {
                                props.comparison.map((item, index) => {
                                    return (

                                        <div className={item.background ? "column-background" : ""} style={item.background ? { backgroundImage: `url(${item.background})` } : { paddingLeft: "0px", marginTop: "0px" }} key={index}>
                                            <div onMouseEnter={() => onHover(item.subtitle)} onMouseLeave={onLeave} className={item.background ? "overlay content-box" : "content-box"} key={index} style={item.background ? {} : { backgroundColor: `${item.bgColor}` }}>

                                                <div className="" >
                                                    {item.icon ? <p className="item-image">{item.icon == "users" ? <FaUsers fontSize="5rem" color="white" /> : item.icon == "desktop" ? <FaDesktop fontSize="5rem" color="white" /> : item.icon == "star" ? <FaStar fontSize="5rem" color="white" /> : ""}</p> : ""}
                                                    <h3>{item.subtitle}</h3>
                                                    <hr style={{ width: "50px", margin: "10px auto" }} />
                                                    
                                                    {hover && hoveredSubtitle === item.subtitle && (
                                                        <div className="hover-content">
                                                            {
                                                                item.info ?
                                                                    <div className="item-description uk-grid uk-grid-collapse">
                                                                        <p className="item-info uk-width-5-6">{item.info}</p>
                                                                    </div>
                                                                    : ""
                                                            }
                                                            {
                                                                item.url ?
                                                                    <div style={{ margin: "30px auto" }}>
                                                                        <a href={item.url} className={item.theme == "light" ? "card-btn dark" : "card-btn light"}>{item.label}</a>
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </div>
    )

}

export default Comparison