import credit from '../../assets/credit1.jpg'
import car from '../../assets/uninsured1.jpg'
import intervention from '../../assets/intervention1.png'
import manchester from '../../assets/manchester.jpg'
import everton from '../../assets/everton.jpg'
import manchester_overlay from '../../assets/manchester_overlay.png'
import everton_overlay from '../../assets/everton_overlay.png'

//companies slider
import image1 from '../../assets/1-dcj.webp'
import image2 from '../../assets/2-wrightsure.webp'
import image3 from '../../assets/3-jbs.webp'
import image4 from '../../assets/4-fnc.webp'
import image5 from '../../assets/5-russell.webp'
import image6 from '../../assets/6-whittlesey.webp'
import image7 from '../../assets/7-htc.webp'
import image8 from '../../assets/8-specialist.webp'
import image9 from '../../assets/9-sib.webp'
import image10 from '../../assets/10-trdirect.webp'
import image11 from '../../assets/11-jabrokers.webp'
import image12 from '../../assets/12-bislogo.webp'
import image13 from '../../assets/13-hinks.webp'
import image14 from '../../assets/14-west-craven.webp'
import image15 from '../../assets/15-wcib.webp'
import image16 from '../../assets/16-business-compare.webp'



export const subpageContent = {
    "credit": [
        {
            paddingTop: true,
            subtitle: "",
            sideImage: "right",
            title: "Credit Hire & Credit Repair",
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: `We provide accident replacement vehicles on a credit hire basis to clients that have been involved in non-fault incidents.
            Credit Hire is essentially the provision of a hire vehicle to a non-fault party for a period while their vehicle is not available for use. This could be while repairs to a vehicle are taking place or if the vehicle is not repairable until the client receives their total loss payment.
            As an Association of British Insurer’s subscriber, our service is determined by fixed rates under the general terms of the agreement. Once the hire vehicle is returned, an invoice is generated and settled directly by the at fault parties insurance company.
            We can supply our clients with like for like vehicles under this service. From small city cars to prestige marques, commercial vans to refrigerated HGV’s, coaches and minibus’ to wheelchair adapted vehicles. Our vehicles and partners fleets have been combined with vast industry experience to ensure our clients’ needs are met and exceeded.`
                },

            ],
            image: credit,
        }
    ],
    "interventionContent": [
        {
            paddingTop: true,
            subtitle: "",
            sideImage: "right",
            title: "Third-Party Intervention",
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: `With our team having over 25 years’ experience in handling motor claims, we have the skills and expertise to take accident management a level further, by providing a Third-Party intervention service.
    
              This service means that our handlers will diligently attempt to contact the Third Party after an incident, and look to provide a replacement vehicle if required, as well as arrange any required repairs. In doing so, we can ensure that our ABI approved repairers and replacement vehicle providers are performing the works in a professional and timely manner, thus ensuring the Third Party are not inflating our client’s own claims experience by using any unscrupulous companies in the industry.
              We can demonstrate the savings that we have made our clients with customised reports, given at any time required, allowing our broker partners to provide an unparalleled level of claims service in the commercial fleet sector.`
                },

            ],
            image: intervention,
        }
    ],
    "recovery": [
        {
            paddingTop: true,
            subtitle: "",
            sideImage: "right",
            title: "Uninsured Loss Recovery",
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: `Our A-rated Legal Expenses Policies are underwritten by RSA Insurance and provide £100,000 indemnity for legal costs, incurred pursuing the recovery of uninsured losses.
              The policy covers all types of vehicles, authorised drivers and passengers. The cover also protects them from the risk of any legal costs that may be incurred as a result of an 
              accident. The policy covers drivers and passengers who may have been injured as a result of an accident and would be able to pursue a personal injury claim against the offending 
              driver; without incurring any legal costs. United Legal look to recover the final repair costs from the third-party insurers, in cases that the repairs have been paid for in 
              full by the claimant. Alternatively, if the claim is dealt with via the claimants’ comprehensive insurance policy, we will look to recover the claimants’ policy excess from the 
              third-party insurers. Proof or receipt of excess payments will be required to pursue such recovery. We also aim to recover loss of use claims for the time the claimants’ vehicle 
              is off the road due to it being unroadworthy; or for the duration of repairs.`
                },

            ],
            image: car,
        }
    ]
}

export const bannerCMS = {
    margin: true,
    backgroundColor: "#f9f9f9",
    themeColor: "#005D8F",
    questionMark: true,
    title: 'Would you like more information about our Claims Management Service?',
    description: 'If you have any questions about our Claims Management service, our team would be more than happy to answer your questions. Simply get in touch today.',
    displayBtn: true,
    label: "Get in Touch",
    link: "true",
    url: "/contact-us",
    bottomPadding: true
}

export const sliderCompanies = {
    theme: 'light',
    title: 'TRUSTED BY HUNDREDS',
    description: '',
    images: [
        {
            image: image1,
        },
        {
            image: image2,
        },
        {
            image: image3,
        },
        {
            image: image4,
        },
        {
            image: image5,
        },
        {
            image: image6,
        },
        {
            image: image7,
        },
        {
            image: image8,
        },
        {
            image: image9,
        },
        {
            image: image10,
        },
        {
            image: image11,
        },
        {
            image: image12,
        },
        {
            image: image13,
        },
        {
            image: image14,
        },
        {
            image: image15,
        },
        {
            image: image16,
        },
    ]
}

export const testimonials = {
    theme: 'dark',
    title: 'Testimonial',
    description: '',
    bgColor: "#005d8f",
    text: [
        {
            subtitle: "James Brown – Managing Director",
            info: "“Having been an Insurance Broker for over 30 years, I can honestly say that the service & professionalism provided by United Legal Assistance is second to none. I would strongly recommend them to anyone.”",
        },
        {
            subtitle: "Chris Breeze – CEO",
            info: "“After experiencing numerous issues with previous providers of motor legal expenses policies, we decided to change to United Legal Assistance in September 2014. During this time, the whole team has provided an exceptional service, as well as an innovative approach to our clients and companies needs. ULA are polite, professional and efficient in their dealing.”",
        },
        {
            subtitle: "Cath Allsobrook – Operations Director",
            info: "“To improve our claims service and provide our clients with a 24 hour backed claims line we decided to test United Legal Assistance. The results were emphatic, with our clients making the effort to tell us of the excellent service they had received in the unfortunate event of a claim. We have since signed up with ULA and our account manager Daniel, has ensured that the transition has been as smooth as possible. The whole team has provided an exceptional service, as well as an innovative approach to our clients and company’s needs. ULA are polite, professional and efficient in their dealing.”",
        },
        {
            subtitle: "Don King – Director Wrightsure Services Ltd",
            info: "“I can confirm that we have used the above Company for the last 12 months, who have been able to produce a Legal Expenses facility for our Clients, who have not had the benefit of this cover and have found ULA to be thoroughly professional in our dealings with them.”",
        },
    ]
}

export const hospitalityCards = {
    twoColumn: true,
    comparison: [
        {
            
            background: manchester,
            overlayImage: manchester_overlay,
            subtitle: "Manchester United Hospitality",
            info: "Fancy a trip to Old Trafford? We’ve got seats in the Evolution Suite at Old Trafford, where we take our clients. So why not save your place, and register your interest?",
            label: "Learn More",
            url: "/manchester-united-hospitality/"
        },
        {
            
            background: everton,
            overlayImage: everton_overlay,
            subtitle: "Everton F.C. Hospitality",
            info: "Or even a trip to Goodison Park? We’ve got seats in the Brian Labone Lounge at Goodison – which is a fabulous day out. So why not save your place, and register your interest?",
            label: "Learn More",
            url: "/everton-fc-hospitality/"
        },
    ]
}