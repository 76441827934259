import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { testimonials, hospitalityCards, subpageContent, sliderCompanies } from "../utils/services/subpageInformation";
import ParallaxComponent from "../components/parallax-component/parallax-component"
import InfoImage from "../components/info-image/info-image";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import Banner from "../components/banner/banner";
import { bannerCMS } from "../utils/generalInformation";




// markup
const Credit = () => {


  return (
    <Layout
      SEOTitle={"Credit Hire & Credit Repair - United Legal Assistance"}
      SEODescription={"We provide accident replacement vehicles on a credit hire basis to clients that have been involved in non-fault incidents."}
      footerDisclaimer={""}>

      <InfoImage props={subpageContent} origin="credit" />

      <Banner props={bannerCMS} />

      <Comparison props={hospitalityCards} />

      <ImageCarousel props={sliderCompanies} />

      <ImageCarousel props={testimonials} />

    </Layout>
  )

}

export default Credit